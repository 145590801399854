
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { MerchantsAuditByDayAPI } from "@/common/api/dataWholeBoard/merchantsAuditByDay";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(MerchantsAuditByDayAPI);

function getCurrentMonthDate() {
  const currentDay = (dayjs() as any).$d;

  const curMonthStartDay = (dayjs().startOf("month") as any).$d;

  return [curMonthStartDay, currentDay];
}

function getLastMonthDate() {
  const lastEndDay = (dayjs().subtract(1, "month").endOf("month") as any).$d;

  const lastStartDay = (dayjs().subtract(1, "month").startOf("month") as any)
    .$d;

  return [lastStartDay, lastEndDay];
}

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
})
export default class MerchantsAudit extends Vue {
  private merchantsAuditByDayApi: any;

  private chooseFields: string[] = [];

  private confirmChooseFields: string[] = [];

  private filterFieldVidible: boolean = false;

  private get showTableFields() {
    const confirmChooseFields = this.confirmChooseFields;

    const showListFields: any[] =
      this.allChooseFields.filter(({ field }) => {
        return confirmChooseFields.includes(field);
      }) || [];

    return showListFields;
  }

  private allChooseFields: any[] = [
    {
      field: "date",
      label: "日期",
    },
    {
      field: "name",
      label: "店铺名称",
    },
    {
      field: "total_money",
      label: "今日营业额",
    },
    {
      field: "new_user",
      label: "新增用户",
    },
    {
      field: "product_money",
      label: "商品收益",
    },
    {
      field: "meituan_price",
      label: "美团券",
    },
    {
      field: "douyin_price",
      label: "抖音券",
    },
    {
      field: "kuaishou_price",
      label: "快手券",
    },
    {
      field: "sport_price",
      label: "体育券",
    },
    {
      field: "income",
      label: "在线支付",
    },
    {
      field: "invite_income",
      label: "邀请会员收益",
    },
    {
      field: "member_recharge",
      label: "会员充值",
    },
    {
      field: "member_consume",
      label: "会员消费",
    },
    {
      field: "shop_member_price",
      label: "单店卡充值",
    },
    {
      field: "shop_member_consume",
      label: "单店卡消费",
    },
    {
      field: "entry_fee",
      label: "报名费",
    },
    {
      field: "open_duration",
      label: "开台时长",
    },
    {
      field: "total_pool_order_num",
      label: "订单量",
    },
    {
      field: "meituan_shop_member_price",
      label: "美团单店卡",
    },
    {
      field: "average_duration",
      label: "平均开台时长",
    },
    {
      field: "shop_member_recharge_rate",
      label: "单店卡充值率",
    },
    {
      field: "member_recharge_rate",
      label: "会员充值率",
    },
    {
      field: "shop_give_coupon_duration",
      label: "赠送优惠券时长",
    },
    {
      field: "free_open_duration",
      label: "免费开台时长",
    },
  ];

  private get curShopId() {
    const route = this.$route;

    const query = route.query || {};

    return query.shop_id;
  }

  private handleWakeFilterFieldPopover() {
    this.chooseFields = [...(this.confirmChooseFields || [])];

    this.filterFieldVidible = true;
  }

  private handleConfirmFilterField() {
    this.confirmChooseFields = [...(this.chooseFields || [])];

    this.filterFieldVidible = false;
  }

  constructor() {
    super();
    this.merchantsAuditByDayApi = new MerchantsAuditByDayAPI();

    const chooseFields: string[] = [];

    const merchantExcelList2ch: any = {};

    for (const { field, label } of this.allChooseFields) {
      chooseFields.push(field);

      merchantExcelList2ch[field] = label;
    }

    this.merchantExcelList2ch = merchantExcelList2ch;

    this.chooseFields = chooseFields;

    this.confirmChooseFields = [...chooseFields];
  }

  private mounted() {
    document.documentElement.addEventListener("click", () => {
      this.filterFieldVidible = false;
    });
  }

  private form: any = {
    time: [...getCurrentMonthDate()],
  };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    // if (this.$route.query.name) {
    //   this.form.shop_name = this.$route.query.name;
    // }
    this.search();
  }

  private pickerOptions: any = {
    shortcuts: [
      {
        text: "本月",
        onClick(picker: any) {
          picker.$emit("pick", getCurrentMonthDate());
        },
      },
      {
        text: "上月",
        onClick(picker: any) {
          picker.$emit("pick", getLastMonthDate());
        },
      },
    ],
  };

  private getTable(): void {
    const params = this.getMerchantsAuditTableListParams();
    this.merchantsAuditByDayApi.business(
      {
        ...(params || {}),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private merchantExcelList2ch: any = {
    // date: "日期",
    // name: "店铺名称",
    // total_money: "总营业额",
    // new_user: "新增用户",
    // product_money: "商品收益",
    // meituan_price: "美团券",
    // douyin_price: "抖音券",
    // income: " 在线支付收益",
    // invite_income: "邀请会员收益",
    // member_consume: "会员消费",
    // member_recharge: "会员充值",
    // shop_member_price: "单店卡充值",
    // shop_member_consume: "单店卡消费",
    // open_duration: "开台时长（分钟）",
    // average_duration: "平均时长（分钟）",
    // total_pool_order_num: "订单数",
  };
  // 商户Excel 表头的先后顺序
  private merchantExcelSortList: string[] = [
    // "date",
    // "name",
    // "total_money",
    // "product_money",
    // "meituan_price",
    // "douyin_price",
    // "income",
    // "invite_income",
    // "member_consume",
    // "shop_member_price",
    // "shop_member_consume",
    // "member_recharge",
    // "open_duration",
    // "average_duration",
    // "total_pool_order_num",
  ];

  private getMerchantsAuditTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      // shop_name, // 店铺名称

      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {
      shop_id: this.curShopId,
    };

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.start_date = startTime;

      outerParams.end_date = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      outerParams.is_export = 1;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 点击导出excel 表格
  private handleExportExcelMerchantAuditList() {
    this.getTable();
    const params: any = this.getMerchantsAuditTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.merchantExcelSortList = [...(this.confirmChooseFields || [])];

    this.downloadExcelLoading = true;
    this.merchantsAuditByDayApi.exportExcelMerchantAuditList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `台球每日营收列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.orderNum =
                typeof orderNum == "number"
                  ? orderNum.toString()
                  : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}
